<script>
import InputField from '@/components/general/InputField'
import ListItem from '@/components/general/ListItem'
import EmptyList from '@/components/general/EmptyList'
import Loading from '@/components/general/Loading.vue'
export default {
  components: { InputField, ListItem, EmptyList, Loading },
  name: 'RemovedDepartments',
  data () {
    return {
      search: '',
      isReady: false,
      data: [],
      departments: []
    }
  },
  created () {
    this.$store.dispatch('attemptSetShowSpinner', true)
    this.getDepartments()
  },
  methods: {
    getDepartments () {
      this.$store.dispatch('attemptGetDepartments')
        .then(({ data }) => {
          this.departments = data.departments.filter(d => d.active === false).map((department) => (
            {
              id: department._id,
              name: department.name
            }
          ))
          this.departments.sort((a, b) => a.name.localeCompare(b.name))
          this.isReady = true
          this.data = this.departments
          this.$store.dispatch('attemptSetShowSpinner', false)
        })
    },
    reactiveDepartment (id) {
      this.$store.dispatch('attemptReactivateDepartments', id)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.departments.labels:reactivate.success')
          })
          this.getDepartments()
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.departments.labels:reactivate.error')
          })
        })
    }
  },
  watch: {
    search: function (value) {
      this.departments = this.data.filter(department => {
        return department.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady"/>
    <div class="removed-departments--container" v-if="isReady">
      <div class="removed-departments--filter-actions">
        <input-field class="mr-2" outlined dense :label="$t('global:search.alt')" v-model="search" append-icon="mdi-magnify"></input-field>
      </div>
      <div class="removed-departments--list">
        <list-item
          v-for="department in departments"
          :key="department.id"
          :text="department.name"
          :modalConfirmTitle="$t('modal.confirmation:departments.reactivate.title')"
          :modalConfirmDescription="$t('modal.confirmation:departments.reactivate.description', { name: department.name })"
          viewBtn
          reactivate
          :item="department.id"
          @reactivateItem="reactiveDepartment"
          @view="$router.push({ name: 'business.admin.center.departments.linked.positions.removed', params: { id: department.id } })"
        ></list-item>
        <empty-list
          v-if="departments.length == 0"
          :title="$t('empty.state.title:department.removed.title')"
          :textOne="$t('empty.state.text:department.removed.text')"
          :image="'/assets/images/empty-bush.png'"
        ></empty-list>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<style lang="scss">
.removed-departments--container {
  .removed-departments--filter-actions {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 940px) {
  .removed-departments--container {
  .removed-departments--filter-actions {
    display: inline;
  }
}
}

</style>
